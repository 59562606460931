import React, {Component} from 'react';
import {
    Grid,
    Container,
    Input,
    Button,
    Label,
    Icon,
    Image,
    Message,
    Card,
    Menu
} from 'semantic-ui-react';
import Utils from '../Utils'
import './AdministrationPage.css'
import * as model from "../models/Models";

interface administrationPageInterface {
    dictionariesInterviews: Dictionary[],
    dictionariesMeetings: Dictionary[],
    word: WordFrontend,
    messageFail: boolean,
    hasMoreItem: boolean,
    availableDictionaries: DictionaryItem[],
    flagChosen: string,
    tabActiveItem: string,
}

interface DictionaryItem {
    code: string,
    description: string
}

interface Dictionary {
    key: string,
    flag: string,
    description: string,
    language: string,
    words: WordFrontend[]
}

interface WordFrontend {
    key: string,
    value: string | undefined,
    starred: boolean
}


var token = localStorage.getItem("jwt");
var user: model.AuthJWT;
Utils.parseJwt(token!).then((response)=>{
    return Utils.getUserData(response,token!);
}).then((userData)=>{
    user = userData;
}).catch((error)=>{
                    console.error(error) // TO DO FIX
                });

export default class AdministrationPage extends Component<any, administrationPageInterface> {

    constructor(props: any) {
        super(props);
        this.state = {
            dictionariesInterviews: [],
            dictionariesMeetings: [],
            word: {key: '', value: '', starred: false},
            messageFail: false,
            hasMoreItem: true,
            availableDictionaries: [],
            flagChosen: "",
            tabActiveItem: 'Interviews',
        };
        this.componentDidMount = this.componentDidMount.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.updateWord = this.updateWord.bind(this);
        this.deleteWord = this.deleteWord.bind(this);
        this.handleItemClick = this.handleItemClick.bind(this);
        this.starWord = this.starWord.bind(this);
        this.unstarWord = this.unstarWord.bind(this);
    }

    componentDidMount() {
        Utils.getAvailableDictionaries().then((responseDictionaries: any) => {
            let availableDictionaries = responseDictionaries["dictionaries"];
            this.setState({availableDictionaries: availableDictionaries});
            this.state.availableDictionaries.forEach((dict: DictionaryItem) => {
                this.addNewDictionaryInterviews(dict);
                this.addNewDictionaryMeetings(dict);
            })
        })
    }


    private addNewDictionaryInterviews(dict: DictionaryItem) {
        let dictId = dict.code;
        let dictDesctiption = dict.description;
        return Utils.getWordsInDictionaryV2("Interviews", dictId).then((responseWords: any) => {
            if (!responseWords) {
                this.setState({messageFail: true})
            }
            let languageCountry = dictId.split('-');
            let words = [] as WordFrontend[];
            if (responseWords.words) {
                responseWords.words.forEach((eachWord: model.Word) => {
                    words.push({
                        key: dictId,
                        value: eachWord.word,
                        starred: eachWord.starred!
                    })
                })
            }
            let dictionaries = this.state.dictionariesInterviews;
            dictionaries.push({
                key: dictId,
                description: dictDesctiption,
                flag: languageCountry[1],
                language: languageCountry[0],
                words: words
            });
            dictionaries.sort(function (a, b) {
                var nameA = a.key.toUpperCase();
                var nameB = b.key.toUpperCase();
                if (nameA < nameB) {
                    return -1;
                } else return 1
            });
            this.setState({dictionariesInterviews: dictionaries});
            return dictionaries;
        });
    }

    private addNewDictionaryMeetings(dict: DictionaryItem) {
        let dictId = dict.code;
        let dictDesctiption = dict.description;
        return Utils.getWordsInDictionaryV2("Meetings", dictId).then((responseWords: any) => {
            if (!responseWords) {
                this.setState({messageFail: true})
            }
            let languageCountry = dictId.split('-');
            let words = [] as WordFrontend[];
            if (responseWords.words) {
                responseWords.words.forEach((eachWord: model.Word) => {
                    words.push({
                        key: dictId,
                        value: eachWord.word,
                        starred: eachWord.starred!
                    })
                })
            }
            let dictionaries = this.state.dictionariesMeetings;
            dictionaries.push({
                key: dictId,
                flag: languageCountry[1],
                description: dictDesctiption,
                language: languageCountry[0],
                words: words
            });
            dictionaries.sort(function (a, b) {
                var nameA = a.key.toUpperCase();
                var nameB = b.key.toUpperCase();
                if (nameA < nameB) {
                    return -1;
                } else return 1
            });
            this.setState({dictionariesMeetings: dictionaries});
            return dictionaries;
        });
    }


    private getUrlFlag(countryCode: string) {
        return Utils.webapp_dir + countryCode.toLowerCase() + ".svg"
    }

    handleItemClick(e: any, selected: any) {
        this.setState({tabActiveItem: selected.name});
    }

    handleChange = (e: any, inputChange: any) => {
        let word: WordFrontend = {
            value: inputChange.value,
            key: inputChange.country,
            starred: false
        };
        this.setState({word: word});
    };

    updateWord = (e: any, inputUpdateWord: any) => {
        let {value} = this.state.word;
        let key = inputUpdateWord.country as string;
        if (value) {
            this.setState({word: {key: key, value: '', starred: false}});
            let splits = value.split(";");
            splits.map((split: string) => {
                return Utils.updateWordsInDictionary(this.state.tabActiveItem, key, split).then((response: any) => {
                    if (response.result === "Word added") {
                        let word: WordFrontend = {
                            value: split,
                            key: key,
                            starred: false
                        };
                        let dictionaries = this.state.tabActiveItem === "Interviews" ? this.state.dictionariesInterviews : this.state.dictionariesMeetings;
                        let dictionary = dictionaries.find(dict => dict.key === this.state.flagChosen);
                        if (dictionary) {
                            let words: WordFrontend[] = dictionary.words;
                            words.push(word);
                            dictionary.words = words;
                            this.state.tabActiveItem === "Interviews" ? this.setState({dictionariesInterviews: dictionaries}) : this.setState({dictionariesMeetings: dictionaries});
                            return this.setState({hasMoreItem: false})
                        }
                    } else {
                        return null;
                    }
                });
            });
        } else {
            return <Message content={'no word to add'}/>
        }
    };

    deleteWord(word: WordFrontend) {
            if (word.value) {
                return Utils.deleteWordFromDictionary(this.state.tabActiveItem, word.key, word.value!).then((response: any) => {
                    console.log("deleted word: " + JSON.stringify(response));
                    if (response) {
                        return Utils.getWordsInDictionaryV2(this.state.tabActiveItem, this.state.flagChosen).then((responseWords: any) => {
                            if (!responseWords) {
                                this.setState({messageFail: true})
                            }
                            let words = [] as WordFrontend[];
                            if (responseWords.words) {
                                responseWords.words.forEach((eachWord: model.Word) => {
                                    words.push({
                                        key: this.state.flagChosen,
                                        value: eachWord.word,
                                        starred: eachWord.starred!
                                    })
                                })
                            }
                            let dictionaries = this.state.tabActiveItem === "Interviews" ? this.state.dictionariesInterviews : this.state.dictionariesMeetings;
                            dictionaries.map(dict => {
                                if(dict.key == this.state.flagChosen) {
                                    dict["words"] = words;
                                }
                                return dict
                            })
                            this.state.tabActiveItem === "Interviews" ? this.setState({dictionariesInterviews: dictionaries}) : this.setState({dictionariesMeetings: dictionaries})
                            return this.setState({hasMoreItem: false})
                        })
                    }
                });
            }
        }


    starWord(word: WordFrontend) {
        Utils.starWordInDictionary(this.state.tabActiveItem, word.key, word.value!).then((response: any) => {
                if (response.result === "Word starred") {
                    let dictionaries = this.state.tabActiveItem === "Interviews" ? this.state.dictionariesInterviews : this.state.dictionariesMeetings;
                    let dictionary = dictionaries.find(dict => dict.key === this.state.flagChosen);
                    if (dictionary) {
                        //Find index of specific word.
                        let words: WordFrontend[] = dictionary.words;
                        let wrdIndex: number = words.findIndex((wrd => wrd.value === word.value));
                        //Deleting word from words.
                        words[wrdIndex].starred = true;
                        dictionary.words = words;
                        this.state.tabActiveItem === "Interviews" ? this.setState({dictionariesInterviews: dictionaries}) : this.setState({dictionariesMeetings: dictionaries});
                    }
                }
            });
        }

    unstarWord(word: WordFrontend) {
        Utils.unstarWordInDictionary(this.state.tabActiveItem, word.key, word.value!).then((response: any) => {
            if (response.result === "Word unstarred") {
                let dictionaries = this.state.tabActiveItem === "Interviews" ? this.state.dictionariesInterviews : this.state.dictionariesMeetings;
                let dictionary = dictionaries.find(dict => dict.key === this.state.flagChosen);
                if (dictionary) {
                    //Find index of specific word.
                    let words: WordFrontend[] = dictionary.words;
                    let wrdIndex: number = words.findIndex((wrd => wrd.value === word.value));
                    //Deleting word from words.
                    words[wrdIndex].starred = false;
                    dictionary.words = words;
                    this.state.tabActiveItem === "Interviews" ? this.setState({dictionariesInterviews: dictionaries}) : this.setState({dictionariesMeetings: dictionaries});
                }
            }
        });
    }

    renderFlagsList() {
        return (
            <Grid>
                <Grid.Row>
                    <Card.Group itemsPerRow={9}>
                        {this.state.dictionariesInterviews.sort().map((dictionary: Dictionary, key) =>
                            <Card key={key + dictionary.key}
                                  style={{"padding": ".5em", "margin": ".5em"}}
                                  onClick={() => {
                                      this.setState({flagChosen: dictionary.key})
                                  }}
                                  raised={this.state.flagChosen === dictionary.key}
                            >
                                <Image fluid src={this.getUrlFlag(dictionary.flag)}/>
                                <Card.Content>
                                    <Card.Header>{dictionary.description}</Card.Header>
                                </Card.Content>
                            </Card>
                        )}
                    </Card.Group>
                </Grid.Row>
            </Grid>
        )
    }

    renderDictionaries() {
        const {tabActiveItem} = this.state;
        const dictionary = this.state.tabActiveItem === "Interviews" ? this.state.dictionariesInterviews.find(dict => dict.key === this.state.flagChosen) :
            this.state.dictionariesMeetings.find(dict => dict.key === this.state.flagChosen);
        if (dictionary) {
            return (
                <Grid textAlign={'center'}>
                    <Grid.Row columns={1} width={14} key={dictionary.key}>
                        <Grid.Column width={14} textAlign={"left"}>
                            <Menu attached='top' tabular size={"large"}>
                                <Menu.Item
                                    name='Interviews'
                                    active={tabActiveItem === 'Interviews'}
                                    onClick={this.handleItemClick}/>
                                <Menu.Item
                                    name='Meetings'
                                    active={tabActiveItem === 'Meetings'}
                                    onClick={this.handleItemClick}/>
                            </Menu>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row width={14}>
                        {this.state.tabActiveItem === 'Interviews' ? this.renderDictionary() : null}
                        {this.state.tabActiveItem === 'Meetings' ? this.renderDictionary() : null}
                    </Grid.Row>
                </Grid>
            )
        }
    }

    renderDictionary() {
        const dictionary = this.state.tabActiveItem === "Interviews" ? this.state.dictionariesInterviews.find(dict => dict.key === this.state.flagChosen) :
            this.state.dictionariesMeetings.find(dict => dict.key === this.state.flagChosen);
        if (dictionary) {
            let {word} = this.state;
            return (
                <Grid>
                    <Grid.Row textAlign={'center'} columns={1}>
                        <Grid.Column>
                            {this.state.messageFail ? <Message content={'NO DICTIONARY AVAILABLE'}/> : null}
                                <Input
                                    type={'text'}
                                    labelPosition='right'
                                    placeholder='word1; word2; ...'
                                    key={dictionary.key + 'input'}
                                    value={word.key === dictionary.key ? word.value : null}
                                    onChange={this.handleChange}
                                    action
                                >
                                    <input value={word.key === dictionary.key ? word.value : undefined}/>
                                    <Button
                                        type={'submit'}
                                        key={dictionary.key + 'updateword'}
                                        language={dictionary.language}
                                        country={dictionary.key}
                                        value={word.value}
                                        onClick={this.updateWord}
                                    >
                                        ADD WORD
                                    </Button>
                                </Input>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row
                        textAlign={'center'}
                        columns={1}
                        centered
                    >
                        <Grid.Column width={14}>
                            <Label.Group
                                alignself={'flex-end'}
                                textalign={"center"}
                                verticalalign={"bottom"}
                                key={dictionary.language + 'labelgroup'}
                            >
                                {dictionary.words.map((word: WordFrontend) => (
                                    <Label
                                        style={{"backgroundColor": "#e5dfd9", "color": "#313131"}}
                                        key={word.value + word.key + 'label'}
                                        value={word.value}
                                    >
                                        {word.value}
                                        <Icon key={word.value} name='delete' onClick={() => this.deleteWord(word)}/>
                                        &nbsp;
                                        {word.key === "wr-WR"?
                                            word.starred?
                                            <Icon key={word.value} color={"yellow"} name='star' onClick={() => this.unstarWord(word)}/> :
                                            <Icon key={word.value} name='star outline' onClick={() => this.starWord(word)}/>
                                            : null
                                        }
                                    </Label>
                                ))}
                            </Label.Group>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )
        }
    }

    renderUser() {
        return (<Container>
            I am sorry. You do not have enough permissions to access this page.
        </Container>)
    }

    renderAdministration() {
        return (
            <Container textAlign='center'>
                {user!.role !== 'admin' ? this.renderUser() :
                    <Grid textAlign={'center'} style={{"padding": "1em"}}>
                        <Grid.Row columns={1} centered verticalAlign={'top'}>
                            <Grid.Column width={12} textAlign={"center"}>
                                {this.renderFlagsList()}
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={1} centered verticalAlign={'middle'}>
                            <Grid.Column width={12} textAlign={"center"}>
                                {this.renderDictionaries()}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                }

            </Container>
        )
    }

    render() {
        return (
            this.renderAdministration()
        )

    }

}