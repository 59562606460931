import React, {Component, Fragment} from 'react';
import './App.css';
import Utils from './Utils';
import UploadView from './pages/UploadView';
import DetailsView from './pages/DetailsView';
import VideoList from './pages/VideoList';
import Administration from './pages/AdministrationPage';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import {Grid, Menu, Icon} from 'semantic-ui-react';
import * as models from './models/Models'


interface AppState {
    isLoggedIn: boolean,
    user: models.AuthJWT,
    loginError: boolean,
    activeItem: string
}


class App extends Component<{}, AppState> {

    constructor(props: {}) {
        super(props);

        this.state = {
            isLoggedIn: false,
            user: {} as models.AuthJWT,
            loginError: false,
            activeItem: '',
        };

        this.authenticate = this.authenticate.bind(this);
        this.handleItemClick = this.handleItemClick.bind(this);
        this.logout = this.logout.bind(this);
    }

    componentDidMount(): void {
        this.authenticate();
        let location = window.location.pathname;
        if (location.includes("admin"))
            this.setState({activeItem:"Administration"});
        else if (location.includes("videos-list"))
            this.setState({activeItem:"Interviews"});
        else if (location.includes("upload"))
            this.setState({activeItem:"Upload"});
        else if (location.includes("details"))
            this.setState({activeItem:"VideoDetails"});
        else
            this.setState({activeItem:"Interviews"});
    }

    handleItemClick = (e:any, {name}:any)=> {
        this.setState({ activeItem: name } );
        switch (name) {
            case "Administration":
                window.location.href = Utils.webapp_dir + "admin";
                break;
            case "Interviews":
                sessionStorage.CurrentPage = 1;
                window.location.href = Utils.webapp_dir + "videos-list";
                break;
            case "Upload":
                window.location.href = Utils.webapp_dir + "upload";
                break;
        }
    };

    authenticate() {
        var jwtInStorage = localStorage.getItem('jwt');
            if(jwtInStorage) {
                Utils.parseJwt(jwtInStorage!).then((response)=>{
                    let user = Utils.getUserData(response,jwtInStorage!);
                    let now = new Date().getTime()/1000;
                    if (user && user.expirationDate > now) {
                        this.setState({isLoggedIn: true, user: user});
                    } else {
                        this.setState({isLoggedIn: false});
                        window.location.href = window.location.origin + "/api/auth/login?final_redirect=" + window.location.pathname;
                    }
                }).catch((error)=>{
                    console.error(error) // TO DO FIX
                });
            }
            else {
                window.location.href = window.location.origin + "/api/auth/login?final_redirect=" + window.location.pathname;
            }
    }


    logout() {
        localStorage.removeItem('jwt');
        window.location.href = document.URL;
        this.setState({
            isLoggedIn: false,
            user: {} as models.AuthJWT
        });
    }

    render() {
        return (
            <Router basename={Utils.webapp_dir}>
                <Fragment>
                    <Grid>
                        <Grid.Row columns={1}
                                  style={{"backgroundColor":"#1b1b1b", "marginBottom":"0", "paddingBottom":"0", "paddingTop":"0", "height":"6.5em!important"}}>
                            <Grid.Column textAlign={'center'} verticalAlign={'middle'}>
                                <img src={Utils.webapp_dir + "Gucci.png"} className={'logo'} alt="logo" onClick={() => {
                                    sessionStorage.CurrentPage = 1;
                                    window.location.href = Utils.webapp_dir;
                                }}/>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row
                            columns={3}
                            textAlign={"center"}
                            style={{
                                "backgroundColor":"#1b1b1b",
                                "paddingTop":"0",
                                "marginTop":"0",
                                "paddingBottom":"0",
                                "marginBottom":"0",
                                "height":"4.3em"
                            }}
                        >
                            <Grid.Column/>
                            <Grid.Column>
                                <Menu
                                    inverted
                                    className={'ms-background'}
                                    borderless
                                    pointing={true}
                                    widths={"2"}
                                >
                                    <Menu.Item
                                        name='Interviews'
                                        active={
                                            this.state.activeItem === 'Interviews' ||
                                            this.state.activeItem === 'VideoDetails'
                                        }
                                        onClick={this.handleItemClick}
                                        link={true}
                                    >
                                        INTERVIEWS
                                    </Menu.Item>
                                    <Menu.Item
                                        name='Upload'
                                        active={this.state.activeItem === 'Upload'}
                                        onClick={this.handleItemClick}
                                        link={true}
                                    >
                                       UPLOAD
                                    </Menu.Item>
                                    {this.state.user.role === 'admin' ?
                                        <Menu.Item
                                            name='Administration'
                                            active={this.state.activeItem === 'Administration'}
                                            onClick={this.handleItemClick}
                                            link={true}
                                        >
                                            ADMINISTRATION
                                        </Menu.Item>
                                        : null
                                    }
                                </Menu>
                            </Grid.Column>
                            <Grid.Column style={{"color":"#e5dfd9", "textAlign":"left", "fontSize":"normal"}} margin-right={"1em"} floated={'right'} >
                                <Grid columns={2}>
                                    <Grid.Column key={'userNameSurnameAndEmail'} textAlign={'right'} width={12}>
                                        <div style={{"fontSize":"small"}}>{this.state.user.display_name}</div>
                                        <div style={{"fontSize":"smaller"}}>{this.state.user.email}</div>
                                    </Grid.Column>
                                    <Grid.Column key={'iconUserHeaderApp'} width={1} textAlign={'left'}>
                                        <Icon name={"user outline"} style={{"color":"white"}} bordered fitted />
                                    </Grid.Column>
                                </Grid>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={1}>
                            <Grid.Column>
                                    <Route exact={true} path="/" component={VideoList}/>
                                    <Route exact={true} path="/upload" component={UploadView}/>
                                    <Route exact={true} path="/details/:videoId" component={DetailsView}/>
                                    <Route exact={true} path="/videos-list" component={VideoList}/>
                                {this.state.user.role === 'admin' ?
                                    <Route exact={true} path="/admin" component={Administration}/>
                                    : null }
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Fragment>
            </Router>
        );
    }
}

export default App;
